import app from '@core/configApp.ts'
import PrimeVue from 'primevue/config'
import StyleClass from 'primevue/styleclass'
import Tooltip from 'primevue/tooltip'
import Ripple from 'primevue/ripple'
import BadgeDirective from 'primevue/badgedirective'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice'
import Toast from 'primevue/toast'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
// eslint-disable-next-line ts/ban-ts-comment
// @ts-expect-error
import Aura from '@presets/aura'
import primeNl from 'primelocale/nl.json'

app.use(PrimeVue, {
  unstyled: true,
  pt: Aura, // apply preset,
  locale: primeNl.nl,
})
app.directive('styleclass', StyleClass)
app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)
app.use(ToastService)
app.component('Toast', Toast)
app.component('ConfirmDialog', ConfirmDialog)
app.component('ConfirmPopup', ConfirmPopup)
app.use(ConfirmationService)
app.directive('badge', BadgeDirective)
