export default {
  content: {
    class: [
      // Spacing
      'p-0',

      // Shape
      'border-0',

      // Color
      'text-surface-700 dark:text-white/80',
      'bg-surface-0 dark:bg-surface-900',
    ],
  },
  header: {
    class: [
      'font-semibold',

      // Spacing
      'py-3 px-4',

      // Color
      'text-surface-800 dark:text-white/80',
      'bg-surface-00 dark:bg-surface-900',
      'border-b border-surface-200 dark:border-surface-700',
    ],
  },
}
