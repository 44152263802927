import './style.css'
import '@core/plugins/plugin-router.ts'
import '@core/plugins/plugin-head.ts'
import '@core/plugins/plugin-fontawesome.ts'
import '@core/plugins/plugin-primevue.ts'
import '@core/plugins/plugin-i18n.ts'
import '~console/theme-detect'
import OpenLayersMap from 'vue3-openlayers'

import app from '@core/configApp.ts'

if (import.meta.env.DEV) {
  if (window.location.hostname === 'localhost') {
    window.location.hostname = 'orderstreet.localhost'
  }
}

app
  .use(OpenLayersMap)
  .mount('#app')
