export default {
  root: 'flex',
  controls: {
    class: [
      // Flexbox & Alignment
      'flex xl:flex-col justify-center gap-2',

      // Spacing
      'p-[1.125rem]',
    ],
  },
  container: {
    class: [
      'flex-auto',

      // Shape
      'rounded-md',

      // Color
      'bg-surface-0 dark:bg-surface-900',
      'border border-surface-200 dark:border-surface-700',
      'outline-none',
    ],
  },
}
